var _a;
import { createLoggerActions } from 'shared/worker/actions/fedopsLogger';
import { VIDEO_SOURCES } from '@wix/wix-vod-constants/dist/common/video-sources';
var SOURCES_INTERACTIONS_MAPPING = (_a = {},
    _a[VIDEO_SOURCES.COMPUTER] = 'VIDEO_PLAY_COMPUTER',
    _a[VIDEO_SOURCES.YOUTUBE] = 'VIDEO_PLAY_YOUTUBE',
    _a[VIDEO_SOURCES.VIMEO] = 'VIDEO_PLAY_VIMEO',
    _a[VIDEO_SOURCES.FACEBOOK] = 'VIDEO_PLAY_FACEBOOK',
    _a[VIDEO_SOURCES.LIVE] = 'VIDEO_PLAY_LIVE',
    _a);
var noopActionCreator = function () { return function () { }; };
var noopInteraction = { start: noopActionCreator, end: noopActionCreator };
function createPlayVideoInteraction(createInteraction) {
    return function (_a) {
        var videoSource = _a.videoSource;
        var interactionName = SOURCES_INTERACTIONS_MAPPING[videoSource];
        if (interactionName === undefined) {
            return noopInteraction;
        }
        return createInteraction(interactionName);
    };
}
function createNewFedopsLogger() {
    var _a = createLoggerActions(undefined), appLoaded = _a.appLoaded, createInteraction = _a.createInteraction;
    return {
        appLoaded: appLoaded,
        playVideo: createPlayVideoInteraction(createInteraction),
    };
}
export default createNewFedopsLogger();
