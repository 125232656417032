import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import focus from '@wix/wix-vod-shared/dist/src/widget/utils/accessibility-focus';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getChannelCoverUrl } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/channel/cover';
import { notForPreview } from 'shared/utils/not-for-preview';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { isClassicLayoutSearchBarVisible, isSearchInputVisible, isCategoriesDropdownVisible, isClassicLayoutHeaderVisible, } from 'shared/selectors/layout';
import { VODCSSModules } from 'shared/utils/wix-connect';
import { storeForReload } from 'widget/utils/reload';
import { openFullScreenChannelOverlay } from 'shared/utils/open-overlay';
import { openFullScreenMemberOnlyOverlay, openFullScreenPurchaseOverlay, openFullScreenSubscribeOverlay, openFullScreenRentOverlay, } from 'widget/utils/open-overlay';
import { playerSize, listThumbnailSize, MIN_WIDGET_WIDTH, } from 'widget/utils/videos-sizes/videos-sizes';
import { selectVideo } from 'widget/redux/client/actions/select-video';
import { getCurrentSiteUser } from 'shared/selectors/current-site-user';
import { searchByString, searchByCategory, searchByTag, } from 'widget/redux/client/actions/search';
import { goToLazyVideosPageIndex } from 'widget/redux/client/lazy-channel-videos/actions';
import { getMainVideo } from 'widget/selectors/get-video';
import { showAutoPlay } from 'widget/selectors/layout';
import { getAllSettings, getNumberOfRows, isMainItemChannelCover, getCanShowSignIn, } from 'shared/selectors/app-settings';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { isVideoPlayingOptimistic, isVideoPausedOptimistic, isVideoEnded, } from 'widget/selectors/video-playback-status';
import { getIsSearching, getSearchQuery } from 'shared/selectors/search';
import { logBi, logWidgetSystem } from 'shared/worker/actions/bi';
import { isSubscriptionButtonVisible } from 'shared/components/overlay-texts/channel/subscribe-button-text-utils';
import { logWidgetVidClick } from 'shared/utils/bi/widget-common-events';
import { sendBiEvent } from 'shared/bi/send-bi-event';
import PlayerBlocksVisibility from 'widget/containers/player-blocks-visibility';
import { requestPlayVideo } from 'widget/redux/client/actions/request-play-video';
import ResizeWidgetByContent from 'widget/layouts/compact/components/resize-widget-by-content/resize-widget-by-content';
import ShareOverlay from 'widget/containers/share-overlay/share-overlay';
import OpenShareOverlay from 'widget/containers/open-share-overlay/open-share-overlay';
import ShareButton from 'widget/components/share-button/share-button';
import AccountButton from 'widget/components/account/account-button/account-button';
import ChannelInfoButton from 'widget/components/channel-actions/channel-info-button';
import ChannelSubscriptionButton from 'widget/components/channel-actions/channel-subscription-button';
import Categories from 'widget/components/categories/categories';
import Tags from 'widget/containers/tags/tags';
import SearchBar from 'widget/components/search-bar/search-bar';
import Videos from 'widget/layouts/classic/components/videos/videos';
import ClassicPlayerOverlay from 'widget/components/player-overlay/classic/classic';
import ComingSoon from 'widget/components/player-overlay/partials/coming-soon';
import { getCurrentChannelId } from 'widget/selectors/channel-info';
import { WidgetScrollbarWrapper } from 'shared/components/scrollbar-wrapper/scrollbar-wrapper';
import EmptySearchResults from 'widget/components/empty-search-results/empty-search-results';
import DeeplinkPopups from 'widget/components/deeplink-popups/deeplink-popups';
import AccessibleVideosContainer from 'widget/components/accessible-videos-container/accessible-videos-container';
import PaymentEvents from 'shared/components/payment-events/payment-events';
import LiveStartHandler from 'widget/components/live-start-handler/live-start-handler';
import { setSavedTime } from 'widget/redux/client/actions/player/set-saved-time';
import { getVideosPerPageCount, getVideoIdsByPageNumber, getVideoIds, getCurrentPageIndex, getIsFetching, getCurrentVideoIndex, hasNextVideo, } from 'widget/redux/client/lazy-channel-videos/selectors';
import { getIsSearchResultEmpty } from 'widget/selectors/search-results';
import NoVideosContent from 'widget/components/no-content/no-videos-content/no-videos-content';
import AutoPlayVideo from 'shared/components/autoplay-video/autoplay-video';
/* containers */
import { WidgetPerformanceLoggers } from 'widget/containers/performance-loggers/performance-loggers';
import ChannelActionsContainer from 'shared/containers/channel-actions/channel-actions';
import { withPlayerModuleLoader } from 'widget/data-components/player-module-loader';
import { withStyles } from 'shared/utils/withStyles';
import styles from './classic.styl';
import * as viewModeSelectors from 'widget/selectors/view-mode';
import { setWidgetHeight } from 'shared/worker/actions/resize/set-widget-height';
import { resizeWindow } from 'shared/worker/actions/resize/resize-window';
import { resizeComponent } from 'shared/worker/actions/resize/resize-component';
import { fitIntoView } from 'shared/worker/actions/fit-into-view';
var DEFAULT_STATE = {
    isTagFocused: false,
    isSearchInputFocused: false,
    searchLayout: false,
    currentCategory: null,
};
var ClassicView = /** @class */ (function (_super) {
    __extends(ClassicView, _super);
    function ClassicView(props) {
        var _this = _super.call(this, props) || this;
        _this.playerOverlayContainerRef = null;
        _this.saveRef = function (name, ref) {
            _this[name] = ref;
        };
        _this.reset = function () {
            _this.setState(__assign(__assign({}, DEFAULT_STATE), { searchBarValue: '' }));
            _this.props.goToLazyVideosPageIndex(0);
        };
        _this.handleThumbnailClick = function (videoItem) {
            var _a = _this.props, selectedVideoId = _a.selectedVideoId, fitIntoView = _a.fitIntoView, selectVideo = _a.selectVideo;
            if (selectedVideoId === videoItem.id) {
                return;
            }
            fitIntoView();
            _this.containerRef.scrollIntoView({ behavior: 'smooth' });
            selectVideo(videoItem.id);
        };
        _this.searchByTag = function (tag) {
            _this.props.logWidgetSystem('videoList.searchByTag.requested');
            _this.setTagsFocusState(false);
            _this.props.searchByTag(tag);
            _this.setState({
                searchBarValue: tag,
                currentCategory: null,
            });
            _this.props.logBi('widget.tags.clicked');
        };
        _this.handleTagsFocus = function () {
            _this.setTagsFocusState(true);
        };
        _this.handleTagsBlur = function () {
            _this.setTagsFocusState(false);
        };
        _this.handleSlideToPrev = function () {
            _this.props.logWidgetSystem('videoList.changePage.requested');
            var _a = _this.props, currentVideosPageNumber = _a.currentVideosPageNumber, goToLazyVideosPageIndex = _a.goToLazyVideosPageIndex;
            goToLazyVideosPageIndex(currentVideosPageNumber - 1);
        };
        _this.handleSlideToNext = function () {
            _this.props.logWidgetSystem('videoList.changePage.requested');
            var _a = _this.props, currentVideosPageNumber = _a.currentVideosPageNumber, goToLazyVideosPageIndex = _a.goToLazyVideosPageIndex;
            goToLazyVideosPageIndex(currentVideosPageNumber + 1);
        };
        _this.handleThumbnailPlayButtonClick = function (videoItem) {
            var channelData = _this.props.channelData;
            _this.playVideo(videoItem);
            _this.props.logWidgetVidClick({ videoItem: videoItem, channelData: channelData });
        };
        _this.handleSearchInputFocus = function () {
            _this.setSearchInputFocusState(true);
        };
        _this.handleSearchInputBlur = function () {
            _this.setSearchInputFocusState(false);
        };
        _this.clearSearch = function () {
            _this.setState({ searchBarValue: '' });
            if (!_this.state.currentCategory) {
                //if category is selected nothing to reset
                _this.props.searchByString('');
            }
        };
        _this.searchByQuery = function (value) {
            _this.props.searchByString(value);
            _this.props.logWidgetSystem('videoList.searchByQuery.requested');
            _this.setState({ currentCategory: null });
        };
        _this.handleSearchInputChange = function (searchBarValue) {
            _this.setState({ searchBarValue: searchBarValue });
        };
        _this.handleCategorySelect = function (category) {
            _this.props.searchByCategory(category);
            _this.props.logWidgetSystem('videoList.searchByCategory.requested');
            _this.setState({
                currentCategory: category,
                searchBarValue: '',
            });
        };
        _this.showChannelInfo = function (event) {
            event.preventDefault();
            _this.props.openFullScreenChannelOverlay(_this.props.channelData.id);
        };
        _this.openMemberOnly = notForPreview(function (id) {
            _this.props.openFullScreenMemberOnlyOverlay(_this.props.channelData.id, id);
        });
        _this.storeDataForReload = function () {
            _this.props.storeForReload({
                selectedVideoId: _this.props.mainVideoId,
            });
        };
        _this.setCurrentVideoFromPayment = function (paymentEvent) {
            if (paymentEvent.itemId) {
                _this.props.selectVideo(paymentEvent.itemId);
            }
        };
        _this.openSubscription = notForPreview(function () {
            var currentChannelId = _this.props.currentChannelId;
            _this.props.logBi('widget.subscription.clicked', {
                channelID: currentChannelId,
            });
            _this.props.openFullScreenSubscribeOverlay(currentChannelId, function () {
                _this.props.logBi('widget.subscriptionPopUp.Completed', {
                    result: 'canceled',
                    errorMsg: '',
                });
            });
        });
        _this.openPurchase = notForPreview(function () {
            var _a = _this.props, currentChannelId = _a.currentChannelId, mainVideoId = _a.mainVideoId;
            _this.props.openFullScreenPurchaseOverlay(currentChannelId, mainVideoId);
        });
        _this.openRent = notForPreview(function () {
            var _a = _this.props, currentChannelId = _a.currentChannelId, mainVideoId = _a.mainVideoId;
            _this.props.openFullScreenRentOverlay(currentChannelId, mainVideoId);
        });
        _this.handleOverlayPlayClick = function (videoItem) {
            var isMemberOnly = videoItem.memberOnly && !_this.props.currentSiteUser;
            if (isMemberOnly) {
                _this.openMemberOnly(videoItem.id);
            }
            else {
                _this.playVideo(videoItem);
            }
        };
        _this.playVideo = function (_a) {
            var id = _a.id;
            _this.props.requestPlayVideo(id);
        };
        _this.playNextVideo = function () {
            var _a = _this.props, id = _a.nextVideoId, channelData = _a.channelData, mainVideo = _a.mainVideo;
            if (id) {
                _this.props.logWidgetVidClick({
                    videoItem: mainVideo,
                    channelData: channelData,
                    buttonName: 'next_video',
                });
                _this.playVideo({ id: id });
            }
        };
        _this.saveContainerRef = function (ref) {
            _this.containerRef = ref;
        };
        _this.state = __assign(__assign({}, DEFAULT_STATE), { searchBarValue: props.searchQuery, isInitialVideosLoaded: false });
        return _this;
    }
    ClassicView.prototype.componentDidMount = function () {
        this.sendSubscriptionDisplayedOnWidget();
        this.props.goToLazyVideosPageIndex(0);
    };
    ClassicView.prototype.componentDidUpdate = function (prevProps) {
        var isSelectedVideoChanged = prevProps.selectedVideoId !== this.props.selectedVideoId;
        if (isSelectedVideoChanged) {
            focus(this.playerOverlayContainerRef);
        }
    };
    ClassicView.prototype.sendSubscriptionDisplayedOnWidget = function () {
        var channelData = this.props.channelData;
        if (isSubscriptionButtonVisible({ channel: channelData })) {
            this.props.sendBiEvent('widget.subscription.displayed', {
                whereDisplayed: 'widget',
            });
        }
    };
    Object.defineProperty(ClassicView.prototype, "listThumbnailSize", {
        get: function () {
            var _a = this.props, appSettings = _a.appSettings, windowSize = _a.windowSize;
            var videosInRow = appSettings.numbers.videosInRow;
            return listThumbnailSize(windowSize.width, videosInRow);
        },
        enumerable: true,
        configurable: true
    });
    ClassicView.prototype.getTagList = function () {
        var _a, _b;
        return ((_b = (_a = this.props.channelData) === null || _a === void 0 ? void 0 : _a.statsInfo) === null || _b === void 0 ? void 0 : _b.tags) || [];
    };
    Object.defineProperty(ClassicView.prototype, "categoriesList", {
        get: function () {
            var categoriesStats = _.get(this.props, 'channelData.statsInfo.categories') || [];
            return _.map(categoriesStats, 'value');
        },
        enumerable: true,
        configurable: true
    });
    ClassicView.prototype.setTagsFocusState = function (isFocused) {
        var _this = this;
        this.setState({
            isTagFocused: isFocused,
        });
        if (!isFocused) {
            setTimeout(function () { return _this.hideSearchLayout(); }, 0);
        }
    };
    Object.defineProperty(ClassicView.prototype, "tags", {
        get: function () {
            var _a;
            var _b = this.state, searchLayout = _b.searchLayout, searchBarValue = _b.searchBarValue;
            var tags = this.getTagList();
            if (!tags.length || !searchLayout || searchBarValue) {
                return null;
            }
            return (React.createElement("div", { className: styles['tags-wrapper'], "data-hook": "tags-wrapper" },
                React.createElement(WidgetScrollbarWrapper, { style: { maxHeight: (_a = this.listThumbnailSize) === null || _a === void 0 ? void 0 : _a.height } },
                    React.createElement(Tags, { styleName: "tags", onTagFocus: this.handleTagsFocus, onTagBlur: this.handleTagsBlur, onTagClick: this.searchByTag, tags: tags }))));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClassicView.prototype, "noSearchResults", {
        get: function () {
            var isSearchResultEmpty = this.props.isSearchResultEmpty;
            var _a = this.state, searchBarValue = _a.searchBarValue, searchLayout = _a.searchLayout;
            if (!isSearchResultEmpty || (searchLayout && !searchBarValue)) {
                return null;
            }
            return React.createElement(EmptySearchResults, { className: styles['no-search-results'] });
        },
        enumerable: true,
        configurable: true
    });
    ClassicView.prototype.isChannelCoverShown = function () {
        return this.props.canShowChannelCover && this.props.isMainItemChannelCover;
    };
    Object.defineProperty(ClassicView.prototype, "videosList", {
        get: function () {
            var _a = this.props, channelData = _a.channelData, currentSiteUser = _a.currentSiteUser, videoIdsByPageNumber = _a.videoIdsByPageNumber, currentVideosPageNumber = _a.currentVideosPageNumber, videosPerPage = _a.videosPerPage, isVideosFetching = _a.isVideosFetching, mainVideo = _a.mainVideo, playerSize = _a.playerSize;
            var _b = this.state, searchLayout = _b.searchLayout, searchBarValue = _b.searchBarValue;
            var searchBlockShown = searchLayout && !searchBarValue && this.getTagList().length;
            var noVideosLoaded = !_.get(videoIdsByPageNumber[currentVideosPageNumber], 'length');
            if (!isVideosFetching && channelData.videosCount === 0) {
                return (React.createElement("section", { styleName: "coming-soon", tabIndex: "0" }, i18n.t('widget.this-channel-is-coming-soon')));
            }
            // TODO: this is crap, need to show videoList OR noSearchResults on upper level
            if (noVideosLoaded || !mainVideo || searchBlockShown) {
                return null;
            }
            return (React.createElement(Videos, { styleName: "videos", containerWidth: playerSize.width, videoIdsByPageNumber: videoIdsByPageNumber, currentVideosPageNumber: currentVideosPageNumber, onSlideToNext: this.handleSlideToNext, onSlideToPrev: this.handleSlideToPrev, channelData: channelData, currentSiteUser: currentSiteUser, videosPerPageCount: videosPerPage, onThumbnailClick: this.handleThumbnailClick, onPlayRequest: this.handleThumbnailPlayButtonClick, onPlayMemberOnlyRequest: this.openMemberOnly, thumbnailSize: this.listThumbnailSize, isSelectedVideoIndicationDisabled: this.isChannelCoverShown() }));
        },
        enumerable: true,
        configurable: true
    });
    ClassicView.prototype.setSearchInputFocusState = function (isFocused) {
        var _this = this;
        this.setState({
            isSearchInputFocused: isFocused,
        });
        if (isFocused) {
            this.showSearchLayout();
        }
        else {
            setTimeout(function () { return _this.hideSearchLayout(); }, 0);
        }
    };
    ClassicView.prototype.showSearchLayout = function () {
        this.setState({ searchLayout: true });
    };
    ClassicView.prototype.hideSearchLayout = function () {
        var _a = this.state, isTagFocused = _a.isTagFocused, isSearchInputFocused = _a.isSearchInputFocused;
        if (!isTagFocused && !isSearchInputFocused) {
            this.setState({
                searchLayout: false,
            });
        }
    };
    Object.defineProperty(ClassicView.prototype, "search", {
        get: function () {
            var _a = this.props, isSearchInputVisible = _a.isSearchInputVisible, searchQuery = _a.searchQuery;
            var currentCategory = this.state.currentCategory;
            if (!isSearchInputVisible) {
                return null;
            }
            var value = currentCategory ? '' : searchQuery;
            return (React.createElement(SearchBar, { value: value, onFocus: this.handleSearchInputFocus, onBlur: this.handleSearchInputBlur, onChange: this.handleSearchInputChange, onSearch: this.searchByQuery, onClose: this.clearSearch, className: styles.search }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClassicView.prototype, "categories", {
        get: function () {
            var _a;
            var _b = this.props, isCategoriesDropdownVisible = _b.isCategoriesDropdownVisible, searchQuery = _b.searchQuery, videosPerPage = _b.videosPerPage, videoByIds = _b.videoByIds;
            var _c = this.state, searchLayout = _c.searchLayout, currentCategory = _c.currentCategory, searchBarValue = _c.searchBarValue;
            if (!isCategoriesDropdownVisible ||
                (!currentCategory && (searchBarValue || searchQuery))) {
                return null;
            }
            var className = classnames(styles['categories-container'], (_a = {},
                _a[styles.hidden] = searchLayout,
                _a));
            return (
            // wrapper needed for focusing when switching from search bar to categories by tab
            React.createElement("div", { className: className },
                React.createElement(Categories, { className: styles.categories, maxOptionsVisible: _.size(videoByIds) > videosPerPage ? 5 : 3, isFocusable: !searchQuery || Boolean(currentCategory), list: this.categoriesList, onCategorySelect: this.handleCategorySelect, selectedCategory: currentCategory })));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClassicView.prototype, "content", {
        get: function () {
            var _a = this.props, isSearchBarVisible = _a.isSearchBarVisible, channelData = _a.channelData, isVideosFetching = _a.isVideosFetching, videoIdsByPageNumber = _a.videoIdsByPageNumber, searchQuery = _a.searchQuery, currentVideosPageNumber = _a.currentVideosPageNumber, isSearching = _a.isSearching;
            var styleNames = classnames('content', {
                'no-search-bar': !isSearchBarVisible,
            });
            return (React.createElement("div", { styleName: styleNames, className: "qa-widget-content" },
                React.createElement("section", { className: styles['search-line'], "aria-label": i18n.t('widget.accessibility.search-videos'), "data-hook": "widget-search-line" },
                    this.search,
                    this.categories),
                this.tags,
                React.createElement(AccessibleVideosContainer, { channelTitle: channelData.title, isChannelHasVideos: Boolean(channelData.videosCount), isVideosFetching: isVideosFetching, isSearching: isSearching, videoIdsByPageNumber: videoIdsByPageNumber, searchQuery: searchQuery, currentVideosPageNumber: currentVideosPageNumber, className: styles['videos-container'], dataHook: "video-list", style: { height: this.videosListHeight }, onRef: memoizedPartial(this.saveRef, 'videosContainerRef') },
                    this.videosList,
                    this.noSearchResults)));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClassicView.prototype, "channelTitle", {
        get: function () {
            var _a = this.props, channelData = _a.channelData, appSettings = _a.appSettings;
            return appSettings.booleans.showChannelTitle ? channelData.title : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClassicView.prototype, "signInButton", {
        get: function () {
            var _a = this.props, isSignInVisible = _a.isSignInVisible, channelData = _a.channelData;
            if (!isSignInVisible) {
                return null;
            }
            return (React.createElement(ChannelActionsContainer, { channelId: channelData.id, onPageRefresh: this.storeDataForReload }, function (channelActions) { return (React.createElement(AccountButton, { className: styles.action, tooltipSide: "bottom", channelData: channelData, onClick: channelActions.showAccountInfo, onLoginClick: channelActions.logIn, onLogoutClick: channelActions.logOut, onSubscribeClick: channelActions.subscribe })); }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ClassicView.prototype, "header", {
        get: function () {
            var _a = this.props, channelData = _a.channelData, isHeaderVisible = _a.isHeaderVisible;
            var styleNames = classnames('header', {
                'empty-header': !isHeaderVisible,
            });
            var channelTitle = this.channelTitle;
            return (React.createElement("header", { styleName: styleNames, "data-hook": "widget-header" },
                React.createElement("h2", { className: styles['channel-name'], "data-hook": "channel-title", title: channelTitle }, channelTitle),
                React.createElement(OpenShareOverlay, null, function (_a) {
                    var toggleShare = _a.toggleShare, ariaLabel = _a.ariaLabel;
                    return (React.createElement(ShareButton, { className: styles.action, ariaLabel: ariaLabel, onClick: toggleShare }));
                }),
                React.createElement(ChannelInfoButton, { styleName: "action", channelData: channelData, onClick: this.showChannelInfo }),
                React.createElement(ChannelSubscriptionButton, { styleName: "action", channelData: channelData, onClick: this.openSubscription }),
                this.signInButton));
        },
        enumerable: true,
        configurable: true
    });
    ClassicView.prototype.renderShareOverlay = function () {
        var _a = this.props, channelData = _a.channelData, mainVideo = _a.mainVideo;
        var key = mainVideo ? mainVideo.id : channelData.id;
        return (React.createElement(ShareOverlay, { key: "share-" + key, channelData: channelData, videoItem: mainVideo }));
    };
    ClassicView.prototype.renderMainSceneContent = function () {
        return (React.createElement("div", { className: styles['main-scene-container'] },
            this.player,
            this.renderShareOverlay()));
    };
    Object.defineProperty(ClassicView.prototype, "player", {
        get: function () {
            var _a = this.props, mainVideo = _a.mainVideo, channelData = _a.channelData, isMainItemChannelCover = _a.isMainItemChannelCover, playerSize = _a.playerSize;
            var width = playerSize.width, height = playerSize.height;
            if (mainVideo) {
                return this.renderPlayerForCurrentDevice(mainVideo);
            }
            return (React.createElement(NoVideosContent, { channelCoverUrl: getChannelCoverUrl(channelData), width: width, height: height, isMainItemChannelCover: isMainItemChannelCover },
                React.createElement(ComingSoon, { className: styles['coming-soon'] })));
        },
        enumerable: true,
        configurable: true
    });
    ClassicView.prototype.renderPlayerForCurrentDevice = function (videoItem) {
        var _this = this;
        var _a = this.props, isVideoPlaying = _a.isVideoPlaying, isVideoEnded = _a.isVideoEnded, canShowChannelCover = _a.canShowChannelCover, channelData = _a.channelData, appSettings = _a.appSettings, nextVideoId = _a.nextVideoId, mainVideo = _a.mainVideo, currentSiteUser = _a.currentSiteUser, showAutoPlay = _a.showAutoPlay, PlayerComponent = _a.PlayerComponent, playerSize = _a.playerSize;
        var classicPlayerOverlay = (React.createElement(ClassicPlayerOverlay, { appSettings: appSettings, currentSiteUser: currentSiteUser, channelData: channelData, videoItem: mainVideo, showChannelCover: canShowChannelCover, nextVideoId: nextVideoId, onPlaySelectedVideo: this.handleOverlayPlayClick, playNextVideo: this.playNextVideo, ended: isVideoEnded, saveRef: memoizedPartial(this.saveRef, 'playerOverlayContainerRef') }));
        if (!PlayerComponent) {
            return (React.createElement("div", { className: styles['player-container'] },
                React.createElement("div", { className: styles.player, style: playerSize }, classicPlayerOverlay)));
        }
        return (React.createElement("div", { className: styles['player-container'] },
            React.createElement(PlayerBlocksVisibility, null, function (_a) {
                var canShowVideoInfoButton = _a.canShowVideoInfoButton, canShowShareButton = _a.canShowShareButton;
                return (React.createElement(PlayerComponent, { styleName: "player", className: "qa-widget-player", videoItem: videoItem, channelData: channelData, width: playerSize.width, height: playerSize.height, paused: !isVideoPlaying, canShowChannelCover: canShowChannelCover, onPurchaseClick: _this.openPurchase, onRentClick: _this.openRent, onSubscribeClick: _this.openSubscription, canShowFullInfo: canShowVideoInfoButton, canShareVideo: canShowShareButton }, classicPlayerOverlay));
            }),
            React.createElement(DeeplinkPopups, null),
            showAutoPlay && React.createElement(AutoPlayVideo, null)));
    };
    ClassicView.prototype.render = function () {
        var _a = this.props, channelData = _a.channelData, isVideoPlaying = _a.isVideoPlaying, mainVideoId = _a.mainVideoId, windowSize = _a.windowSize, isEditor = _a.isEditor;
        var style = isEditor
            ? {
                width: '100%',
                height: '100%',
            }
            : {
                width: windowSize.width,
                minHeight: windowSize.height,
            };
        return (React.createElement(ResizeWidgetByContent, { minWidth: MIN_WIDGET_WIDTH },
            React.createElement("main", { ref: this.saveContainerRef, className: styles.container, style: style, "data-hook": "widget-container", "data-channel-layout": "classic", "aria-label": i18n.t('widget.accessibility.channel-videos-widget', {
                    channelTitle: channelData.title,
                }), tabIndex: 0 },
                this.header,
                this.renderMainSceneContent(),
                this.content,
                React.createElement(PaymentEvents, { onRent: this.setCurrentVideoFromPayment, onSale: this.setCurrentVideoFromPayment, onSubscription: this.reset, onSubscriptionCancel: this.reset }),
                React.createElement(LiveStartHandler, { playVideo: this.playVideo, isVideoPlaying: isVideoPlaying, selectedVideoId: mainVideoId }),
                React.createElement(WidgetPerformanceLoggers, null))));
    };
    ClassicView.displayName = 'ClassicView';
    ClassicView.propTypes = {
        resizeWindow: PropTypes.func.isRequired,
        setWidgetHeight: PropTypes.func.isRequired,
        isSite: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        currentSiteUser: PropTypes.object,
        currentChannelId: PropTypes.string,
        mainVideoId: PropTypes.string,
        mainVideo: PropTypes.object,
        selectVideo: PropTypes.func.isRequired,
        canShowChannelCover: PropTypes.bool.isRequired,
        nextVideoId: PropTypes.string,
        channelData: PropTypes.object.isRequired,
        videoByIds: PropTypes.object.isRequired,
        appSettings: PropTypes.object.isRequired,
        windowSize: PropTypes.object.isRequired,
        isVideoEnded: PropTypes.bool,
        isSearchBarVisible: PropTypes.bool,
        isSearchInputVisible: PropTypes.bool,
        isCategoriesDropdownVisible: PropTypes.bool,
        isHeaderVisible: PropTypes.bool.isRequired,
        isSignInVisible: PropTypes.bool,
        isSearching: PropTypes.bool,
        videosPerPage: PropTypes.number,
        searchQuery: PropTypes.string,
        numberOfRows: PropTypes.number,
        setSavedTime: PropTypes.func,
        goToLazyVideosPageIndex: PropTypes.func.isRequired,
        currentVideosPageNumber: PropTypes.number.isRequired,
        videoIdsByPageNumber: PropTypes.array.isRequired,
        isVideosFetching: PropTypes.bool.isRequired,
        searchByString: PropTypes.func.isRequired,
        searchByTag: PropTypes.func.isRequired,
        searchByCategory: PropTypes.func.isRequired,
        isSearchResultEmpty: PropTypes.bool.isRequired,
        isMainItemChannelCover: PropTypes.bool.isRequired,
        requestPlayVideo: PropTypes.func.isRequired,
        fitIntoView: PropTypes.func.isRequired,
        selectedVideoId: PropTypes.string,
        isVideoPlaying: PropTypes.bool.isRequired,
        showAutoPlay: PropTypes.bool,
        PlayerComponent: PropTypes.func,
        resizeComponent: PropTypes.func.isRequired,
        playerSize: PropTypes.shape({
            width: PropTypes.number.isRequired,
            height: PropTypes.number.isRequired,
        }).isRequired,
    };
    ClassicView = __decorate([
        connect(function (state) {
            var lazyLoadedVideoIds = getVideoIds(state);
            var currentIdIndex = getCurrentVideoIndex(state);
            var nextVideoId = hasNextVideo(state)
                ? lazyLoadedVideoIds[currentIdIndex + 1]
                : null;
            return {
                isSite: viewModeSelectors.isSiteMode(state),
                isEditor: viewModeSelectors.isEditorMode(state),
                windowSize: state.windowSize,
                mainVideoId: getMainVideoId(state),
                mainVideo: getMainVideo(state),
                nextVideoId: nextVideoId,
                selectedVideoId: state.selectedVideoId,
                isVideoPlaying: isVideoPlayingOptimistic(state),
                isVideoPaused: isVideoPausedOptimistic(state),
                isVideoEnded: isVideoEnded(state),
                currentChannelId: getCurrentChannelId(state),
                canShowChannelCover: !state.isVideosTouched,
                appSettings: getAllSettings(state),
                currentSiteUser: getCurrentSiteUser(state),
                firstChannelVideoId: state.firstChannelVideoId,
                numberOfRows: getNumberOfRows(state),
                videoByIds: getVideosGroupedByIds(state),
                isSignInVisible: getCanShowSignIn(state),
                isSearchBarVisible: isClassicLayoutSearchBarVisible(state),
                isSearchInputVisible: isSearchInputVisible(state),
                isCategoriesDropdownVisible: isCategoriesDropdownVisible(state),
                isHeaderVisible: isClassicLayoutHeaderVisible(state),
                searchQuery: getSearchQuery(state),
                channelData: getChannelForWidget(state),
                videosPerPage: getVideosPerPageCount(state),
                videoIdsByPageNumber: getVideoIdsByPageNumber(state),
                currentVideosPageNumber: getCurrentPageIndex(state),
                isVideosFetching: getIsFetching(state),
                isSearching: getIsSearching(state),
                isSearchResultEmpty: getIsSearchResultEmpty(state),
                isMainItemChannelCover: isMainItemChannelCover(state),
                showAutoPlay: showAutoPlay(state),
                playerSize: playerSize(state.windowSize.width),
            };
        }, {
            selectVideo: selectVideo,
            searchByString: searchByString,
            searchByCategory: searchByCategory,
            searchByTag: searchByTag,
            setSavedTime: setSavedTime,
            goToLazyVideosPageIndex: goToLazyVideosPageIndex,
            requestPlayVideo: requestPlayVideo,
            logBi: logBi,
            logWidgetSystem: logWidgetSystem,
            logWidgetVidClick: logWidgetVidClick,
            storeForReload: storeForReload,
            sendBiEvent: sendBiEvent,
            openFullScreenMemberOnlyOverlay: openFullScreenMemberOnlyOverlay,
            openFullScreenPurchaseOverlay: openFullScreenPurchaseOverlay,
            openFullScreenSubscribeOverlay: openFullScreenSubscribeOverlay,
            openFullScreenRentOverlay: openFullScreenRentOverlay,
            setWidgetHeight: setWidgetHeight,
            resizeWindow: resizeWindow,
            resizeComponent: resizeComponent,
            openFullScreenChannelOverlay: openFullScreenChannelOverlay,
            fitIntoView: fitIntoView,
        }),
        withStyles(styles),
        VODCSSModules(styles)
    ], ClassicView);
    return ClassicView;
}(React.Component));
export { ClassicView };
export default withPlayerModuleLoader(ClassicView);
