import { __assign, __awaiter, __decorate, __extends, __generator } from "tslib";
import React from 'react';
import _ from 'lodash';
import { getScreenSizeByUserAgent, isLandscape, } from 'shared/utils/device/screen';
import { withWidgetProps } from 'widget/containers/widget-props';
function isMobileWidgetVisibleInViewPort(container) {
    return __awaiter(this, void 0, void 0, function () {
        var rect, _a, top, bottom, _b, width, height, screenHeight;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!global.Wix) return [3 /*break*/, 2];
                    return [4 /*yield*/, new Promise(global.Wix.getBoundingRectAndOffsets)];
                case 1:
                    _a = (_c.sent()).rect;
                    return [3 /*break*/, 3];
                case 2:
                    _a = container.getBoundingClientRect();
                    _c.label = 3;
                case 3:
                    rect = _a;
                    top = rect.top, bottom = rect.bottom;
                    _b = getScreenSizeByUserAgent(), width = _b.width, height = _b.height;
                    screenHeight = isLandscape() ? width : height;
                    return [2 /*return*/, ((top <= 0 && bottom >= screenHeight) || (top >= 0 && bottom <= screenHeight))];
            }
        });
    });
}
export var visibleInViewPort = function (WrappedComponent) {
    var VisibleInViewPort = /** @class */ (function (_super) {
        __extends(VisibleInViewPort, _super);
        function VisibleInViewPort() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.state = {
                isVisible: __SERVER__,
            };
            _this.check = _.debounce(function () {
                isMobileWidgetVisibleInViewPort(_this.containerRef).then(_this.updateVisibility);
            }, 400);
            _this.updateVisibility = function (newIsVisible) {
                if (newIsVisible !== _this.state.isVisible) {
                    _this.setState({
                        isVisible: newIsVisible,
                    });
                }
            };
            _this.saveRef = function (ref) {
                _this.containerRef = ref;
            };
            return _this;
        }
        VisibleInViewPort.prototype.componentDidMount = function () {
            if (__SERVER__) {
                return;
            }
            this.check();
            this.props.registerToScroll(this.check);
        };
        VisibleInViewPort.prototype.componentWillUnmount = function () {
            if (global.Wix) {
                global.Wix.removeEventListener(global.Wix.Events.SCROLL, this.check);
            }
            this.check.cancel();
        };
        VisibleInViewPort.prototype.render = function () {
            return (React.createElement("div", { role: "presentation", ref: this.saveRef },
                React.createElement(WrappedComponent, __assign({}, this.props, { isVisibleInViewport: this.state.isVisible }))));
        };
        VisibleInViewPort = __decorate([
            withWidgetProps(function (widgetProps) { return ({
                registerToScroll: widgetProps.host.registerToScroll,
            }); })
        ], VisibleInViewPort);
        return VisibleInViewPort;
    }(React.Component));
    VisibleInViewPort.WixWrappedComponent = WrappedComponent;
    return VisibleInViewPort;
};
