import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Slider from 'shared/components/slider/slider';
import { withStyles } from 'shared/utils/withStyles';
import styles from './video-list.styl';
import VideoThumbnail from 'shared/components/video-thumbnail/video-thumbnail';
import ActionCallbacks from 'widget/containers/action-callbacks/action-callbacks';
import { loadMore } from 'widget/redux/client/lazy-channel-videos/actions';
import { getIsFetching, getCursor, } from 'widget/redux/client/lazy-channel-videos/selectors';
import ClampText from '@wix/wix-vod-shared/dist/src/common/components/clamp-text/clamp-text';
import { ALIGNMENT } from '@wix/wix-vod-shared/dist/src/widget/ui-components/thumbnail/content/content';
import { getThumbnailSpacing } from 'shared/selectors/app-settings';
var BREAKPOINTS = [
    {
        max: 639,
        width: 560,
        height: 315,
    },
    {
        max: 1279,
        width: 592,
        height: 333,
    },
    {
        min: 1280,
        width: 608,
        height: 342,
    },
];
var VideoList = /** @class */ (function (_super) {
    __extends(VideoList, _super);
    function VideoList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loadMore = function (itemsToLoadCount) {
            var _a = _this.props, nextVideosCursor = _a.nextVideosCursor, isFetching = _a.isFetching, loadMore = _a.loadMore;
            if (nextVideosCursor && !isFetching) {
                loadMore(itemsToLoadCount);
            }
        };
        _this.renderThumbnailContent = function (_a) {
            var _b;
            var title = _a.title, description = _a.description, publisher = _a.publisher, alignment = _a.alignment, onClick = _a.onClick;
            var classes = classNames(styles.content, (_b = {},
                _b[styles['align-left']] = alignment === ALIGNMENT.LEFT,
                _b[styles['align-center']] = alignment === ALIGNMENT.CENTER,
                _b[styles['align-right']] = alignment === ALIGNMENT.RIGHT,
                _b));
            return (React.createElement("div", { className: classes, onClick: onClick },
                React.createElement("div", { className: styles.publisher }, publisher),
                React.createElement(ClampText, { text: title, className: styles.title }),
                React.createElement(ClampText, { text: description, className: styles.description })));
        };
        _this.renderItem = function (videoId) {
            var _a = _this.props, videoByIds = _a.videoByIds, channel = _a.channel, currentSiteUser = _a.currentSiteUser, onPlayRequestedBi = _a.onPlayRequestedBi, thumbnailSpacing = _a.thumbnailSpacing;
            var isLoading = !videoId;
            var video = isLoading ? {} : videoByIds[videoId];
            return (React.createElement("div", { style: { paddingRight: thumbnailSpacing } },
                React.createElement(ActionCallbacks, { channelId: channel.id, videoItem: video, onPlayRequestedBi: onPlayRequestedBi, key: videoId },
                    React.createElement(VideoThumbnail, { isLoading: isLoading, videoItem: video, key: videoId, channelData: channel, isContentFocusable: true, currentSiteUser: currentSiteUser, breakpoints: BREAKPOINTS, renderThumbnailContent: _this.renderThumbnailContent }))));
        };
        return _this;
    }
    VideoList.prototype.render = function () {
        var _a = this.props, videoIds = _a.videoIds, itemsCount = _a.itemsCount;
        return (React.createElement("div", { className: styles.container },
            React.createElement(Slider, { items: videoIds, itemsCount: itemsCount, renderItem: this.renderItem, arrowClassName: styles.arrow, itemClassName: classNames(styles.item), loadMore: this.loadMore })));
    };
    VideoList.propTypes = {
        thumbnailSpacing: PropTypes.number.isRequired,
        channel: PropTypes.object.isRequired,
        videoIds: PropTypes.array.isRequired,
        videoByIds: PropTypes.object.isRequired,
        loadMore: PropTypes.func.isRequired,
        onPlayRequestedBi: PropTypes.func,
        isFetching: PropTypes.bool.isRequired,
        nextVideosCursor: PropTypes.string,
        itemsCount: PropTypes.number,
    };
    VideoList = __decorate([
        connect(function (state) { return ({
            thumbnailSpacing: getThumbnailSpacing(state),
            nextVideosCursor: getCursor(state),
            isFetching: getIsFetching(state),
        }); }, {
            loadMore: loadMore,
        }),
        withStyles(styles)
    ], VideoList);
    return VideoList;
}(React.Component));
export default VideoList;
