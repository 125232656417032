import { OPEN_SLIDE_MENU, OPEN_VIDEO_VIEW, OPEN_CHANNEL_VIEW, } from './interactions';
import { createLoggerActions } from 'shared/worker/actions/fedopsLogger';
export function createBaseMobileLogger(loggerName) {
    var _a = createLoggerActions(loggerName), appLoaded = _a.appLoaded, createInteraction = _a.createInteraction;
    return {
        appLoaded: appLoaded,
        openSlideMenu: createInteraction(OPEN_SLIDE_MENU),
        openVideoView: createInteraction(OPEN_VIDEO_VIEW),
        openChannelView: createInteraction(OPEN_CHANNEL_VIEW),
    };
}
