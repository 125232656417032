import { __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isButtonsTextShown } from 'shared/selectors/app-settings';
import { purchaseButtonText } from 'mobile/views/home/ui-selectors/buttons';
var PurchaseButton = /** @class */ (function (_super) {
    __extends(PurchaseButton, _super);
    function PurchaseButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PurchaseButton.prototype.render = function () {
        var _a = this.props, text = _a.text, onClick = _a.onClick, icon = _a.icon, className = _a.className, isTextShown = _a.isTextShown;
        return text ? (React.createElement("div", { "data-hook": "paid-access-button", className: className, onClick: onClick },
            icon,
            isTextShown && text)) : null;
    };
    PurchaseButton.propTypes = {
        videoItem: PropTypes.object.isRequired,
        text: PropTypes.object,
        icon: PropTypes.node,
        onClick: PropTypes.func.isRequired,
        isTextShown: PropTypes.bool,
        className: PropTypes.string,
    };
    PurchaseButton = __decorate([
        connect(function (state, props) { return ({
            text: purchaseButtonText(state, props),
            isTextShown: isButtonsTextShown(state),
        }); })
    ], PurchaseButton);
    return PurchaseButton;
}(React.Component));
export default PurchaseButton;
