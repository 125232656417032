import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notForPreview } from 'shared/utils/not-for-preview';
import { isPlayInFrame } from 'shared/selectors/app-settings';
import { handleLogOutPaymentModal } from 'shared/utils/handle-logout-payment-modal';
import { isVideoPlayingOptimistic } from 'widget/selectors/video-playback-status';
import { playVideo, pauseVideo, } from 'widget/redux/client/actions/player/change-playback-status';
import { selectVideo } from 'widget/redux/client/actions/select-video';
import { openFullScreenVideoOverlay, closeFullScreenVideoOverlay, } from 'widget/redux/client/actions/full-screen-modal';
import { openFullScreenPurchaseOverlay, openFullScreenMemberOnlyOverlay, openFullScreenSubscribeOverlay, openFullScreenRentOverlay, } from 'widget/utils/open-overlay';
import { isPortableDevice } from 'shared/selectors/form-factor';
var ActionCallbacks = /** @class */ (function (_super) {
    __extends(ActionCallbacks, _super);
    function ActionCallbacks() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectVideo = function () {
            var _a = _this.props, selectVideo = _a.selectVideo, videoItem = _a.videoItem;
            selectVideo(videoItem.id);
        };
        _this.showVideoAtOverlay = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoItem = _a.videoItem, openFullScreenVideoOverlay = _a.openFullScreenVideoOverlay, closeFullScreenVideoOverlay = _a.closeFullScreenVideoOverlay;
            _this.pausePlayingVideo();
            openFullScreenVideoOverlay(channelId, videoItem.id, false, closeFullScreenVideoOverlay);
        });
        _this.showVideoMemberOnlyInfo = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoItem = _a.videoItem;
            _this.pausePlayingVideo();
            _this.props.openFullScreenMemberOnlyOverlay(channelId, videoItem.id);
        });
        _this.showVideoPurchaseInfo = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoItem = _a.videoItem;
            _this.pausePlayingVideo();
            _this.props.openFullScreenPurchaseOverlay(channelId, videoItem.id);
        });
        _this.showVideoRentInfo = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoItem = _a.videoItem;
            _this.pausePlayingVideo();
            _this.props.openFullScreenRentOverlay(channelId, videoItem.id);
        });
        _this.showChannelSubscriptionInfoAtOverlay = notForPreview(function () {
            _this.pausePlayingVideo();
            _this.props.openFullScreenSubscribeOverlay(_this.props.channelId);
        });
        _this.playVideoAtOverlay = notForPreview(function () {
            var _a = _this.props, channelId = _a.channelId, videoItem = _a.videoItem, openFullScreenVideoOverlay = _a.openFullScreenVideoOverlay, closeFullScreenVideoOverlay = _a.closeFullScreenVideoOverlay;
            _this.pausePlayingVideo();
            openFullScreenVideoOverlay(channelId, videoItem.id, true, closeFullScreenVideoOverlay);
        });
        _this.handlePlayVideo = function () {
            var _a = _this.props, isPlayInFrame = _a.isPlayInFrame, onPlayRequestedBi = _a.onPlayRequestedBi, videoItem = _a.videoItem, isPortableDevice = _a.isPortableDevice;
            onPlayRequestedBi(videoItem);
            if (isPlayInFrame) {
                _this.playVideo();
                return;
            }
            if (isPortableDevice) {
                _this.showVideoAtOverlay();
            }
            else {
                _this.playVideoAtOverlay();
            }
        };
        return _this;
    }
    ActionCallbacks.prototype.componentDidMount = function () {
        handleLogOutPaymentModal({
            onRent: this.showVideoRentInfo,
            onPurchase: this.showVideoPurchaseInfo,
            onSubscription: this.showChannelSubscriptionInfoAtOverlay,
        });
    };
    ActionCallbacks.prototype.playVideo = function () {
        var _a = this.props, playVideo = _a.playVideo, videoItem = _a.videoItem;
        this.selectVideo();
        setTimeout(function () {
            // TODO: get rid of it, might not work in some cases
            playVideo(videoItem.id);
        });
    };
    ActionCallbacks.prototype.pausePlayingVideo = function () {
        var _a = this.props, isVideoPlaying = _a.isVideoPlaying, pauseVideo = _a.pauseVideo;
        if (isVideoPlaying) {
            pauseVideo();
        }
    };
    ActionCallbacks.prototype.render = function () {
        var children = this.props.children;
        return React.cloneElement(children, {
            onClick: this.handlePlayVideo,
            onPlayRequest: this.handlePlayVideo,
            onPlayMemberOnlyRequest: this.showVideoMemberOnlyInfo,
            onPlayWithoutPreviewRequest: this.showVideoAtOverlay,
            onPurchaseRequest: this.showVideoPurchaseInfo,
            onSubscriptionRequest: this.showChannelSubscriptionInfoAtOverlay,
            onRentRequest: this.showVideoRentInfo,
        });
    };
    ActionCallbacks.propTypes = {
        children: PropTypes.element.isRequired,
        selectVideo: PropTypes.func.isRequired,
        playVideo: PropTypes.func.isRequired,
        pauseVideo: PropTypes.func.isRequired,
        onPlayRequestedBi: PropTypes.func,
        openFullScreenVideoOverlay: PropTypes.func,
        closeFullScreenVideoOverlay: PropTypes.func,
        channelId: PropTypes.string.isRequired,
        videoItem: PropTypes.object.isRequired,
        isPlayInFrame: PropTypes.bool.isRequired,
        isVideoPlaying: PropTypes.bool.isRequired,
    };
    ActionCallbacks.defaultProps = {
        onPlayRequestedBi: _.noop,
    };
    ActionCallbacks = __decorate([
        connect(function (state) { return ({
            isPlayInFrame: isPlayInFrame(state),
            isVideoPlaying: isVideoPlayingOptimistic(state),
            isPortableDevice: isPortableDevice(state),
        }); }, {
            playVideo: playVideo,
            pauseVideo: pauseVideo,
            selectVideo: selectVideo,
            openFullScreenVideoOverlay: openFullScreenVideoOverlay,
            closeFullScreenVideoOverlay: closeFullScreenVideoOverlay,
            openFullScreenPurchaseOverlay: openFullScreenPurchaseOverlay,
            openFullScreenMemberOnlyOverlay: openFullScreenMemberOnlyOverlay,
            openFullScreenSubscribeOverlay: openFullScreenSubscribeOverlay,
            openFullScreenRentOverlay: openFullScreenRentOverlay,
        })
    ], ActionCallbacks);
    return ActionCallbacks;
}(React.Component));
export default ActionCallbacks;
