import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isPlayInFrame } from 'shared/selectors/app-settings';
import { isVideoPlayingOptimistic } from 'widget/selectors/video-playback-status';
import { getMainVideo } from 'widget/selectors/get-video';
import PlayerBlocksVisibility from 'widget/containers/player-blocks-visibility';
import PlayerActionCallbacks from 'widget/layouts/compact/actions/player-action-callbacks';
var Player = /** @class */ (function (_super) {
    __extends(Player, _super);
    function Player() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Player.prototype.render = function () {
        var _a = this.props, channelData = _a.channelData, isPlayInFrame = _a.isPlayInFrame, isVideoPlaying = _a.isVideoPlaying, mainVideo = _a.mainVideo, width = _a.width, height = _a.height, PlayerComponent = _a.PlayerComponent, isPortableDevice = _a.isPortableDevice;
        if (!PlayerComponent) {
            return null;
        }
        var playerProps = {
            videoItem: mainVideo,
            channelData: channelData,
            width: width,
            height: height,
            paused: !isVideoPlaying,
        };
        var callbackProps = {
            channelId: channelData.id,
            videoId: mainVideo.id,
        };
        if (isPortableDevice || isPlayInFrame) {
            return (React.createElement(PlayerBlocksVisibility, null, function (_a) {
                var canShowVideoInfoButton = _a.canShowVideoInfoButton, canShowShareButton = _a.canShowShareButton;
                return (React.createElement(PlayerActionCallbacks, __assign({}, callbackProps),
                    React.createElement(PlayerComponent, __assign({}, playerProps, { showPoster: false, showInitialOverlay: false, canShowFullInfo: canShowVideoInfoButton, canShareVideo: canShowShareButton }))));
            }));
        }
        return null;
    };
    Player.propTypes = {
        channelData: PropTypes.object.isRequired,
        isPlayInFrame: PropTypes.bool.isRequired,
        isVideoPlaying: PropTypes.bool.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        mainVideo: PropTypes.object,
        PlayerComponent: PropTypes.func,
        isPortableDevice: PropTypes.bool,
    };
    Player = __decorate([
        connect(function (state) { return ({
            channelData: getChannelForWidget(state),
            mainVideo: getMainVideo(state),
            isPlayInFrame: isPlayInFrame(state),
            isVideoPlaying: isVideoPlayingOptimistic(state),
        }); })
    ], Player);
    return Player;
}(React.Component));
export default Player;
